<template>
  <el-input
      v-model="code"
      placeholder="短信验证码"
      type="text"
      autocomplete="on" :maxlength="codeLen"
      v-bind="$attrs"
      v-on="$listeners"
      @change="handleChange">
    <template slot="append">
      <el-button type="success" style="width: 120px;"
                 @click="getPhoneCode">{{codeBtnText}}</el-button>
    </template>
  </el-input>
</template>

<script>
  import {sendSmsCode} from "@/page-home/api/auth";
  import {isMobile} from "@/page-home/utils/validate";

  const CODE_TIME_COUNT = 60
  export default {
    name: "SmsInput",
    props: {
      codeLen: {
        type: Number,
        default: 6
      },
      codeType: {
        type: Number,
        default: 1
      },
      codeForm: {
        type: String
      }
    },

    data() {
      return {
        code: '',
        codeCount: CODE_TIME_COUNT,
        codeBtnText: '获取验证码',
        codeBtnDisabled: false,
        timer: null,
      }
    },

    methods: {
      getPhoneCode() {
        if (this.codeBtnDisabled) return
        let p = this.$parent.$parent.$parent
        let phone
        if (p[this.codeForm]) {
           phone = this.$parent.$parent.$parent[this.codeForm].phone
        } else {
          phone = p.$parent[this.codeForm].phone
        }
        if (!isMobile(phone)) {
          this.$message.error('请检查手机号是否输入正确')
          return
        }
        this.codeBtnDisabled = true
        const payload = {
          phone,
          type: this.codeType
        }
        sendSmsCode(payload).then(res => {
          this.$message.success('验证码发送成功')
          this.codeBtnText = this.codeCount + ' s'
          this.timer = setInterval(() => {
            if (this.codeCount > 0 && this.codeCount <= CODE_TIME_COUNT) {
              this.codeCount--
              this.codeBtnText = this.codeCount + ' s'
            } else {
              this.codeCount = CODE_TIME_COUNT
              clearInterval(this.timer)
              this.codeBtnText = "重新获取"
              this.timer = null
              this.codeBtnDisabled = false
            }
          }, 1000)
        }).catch(err => {
          this.codeBtnDisabled = false
          this.$message.error(err.msg)
        })
      },

      handleChange(value) {
        this.$emit('input', this.code)
      }
    },

    beforeDestroy() {
      this.timer = null
    }
  }
</script>

<style scoped>

</style>
